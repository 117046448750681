import * as api from '../../api';
import style from './event-details.module.scss';
import { getDateStrFromDateStr, getDurationFromMinutes } from '../../common/dateutil';
import Material from '../shared/Material/material';
import { EventSignupElement } from '../../models/umbracoElement';
import CTAButton from '../shared/Buttons/CTAButton/cta-button';
import CustomAccordion from '../shared/CustomAccordion/custom-accordion';
import React, { Fragment } from 'react';
import { AccordionElementProps } from '../shared/CustomAccordion/AccordionElement/accordion-element';
import Refusion from '../Refusion/refusion';

interface SessionsProps {
    memberid:string,
    event: api.Event,
    eventEnded: boolean;
    signedUpSessionIds: string[];
    isMemberSignedIn: boolean;
    elementData: EventSignupElement;
    openSessionModal: (session: api.EventSession, cancel?: boolean) => void
}
function Sessions(props: SessionsProps) {
    const { event,
        eventEnded,
        signedUpSessionIds,
        isMemberSignedIn,
        elementData } = props;

    const currentEvent = event;
    const hasSessionEnded = (endtime?: string | null) => {
        if (!endtime)
            return false;
        return new Date(endtime) < new Date();
    }
    const createAccordionData = React.useCallback((s: api.EventSession) => {
        const eventdata: AccordionElementProps[] =
            [{
                title: "Praktisk info",
                byDefaultOpen: true,
                dropdownValues: [
                    { title: "Startdato", value: getDateStrFromDateStr(s.startDateTime || "") },
                    { title: "Slutdato", value: getDateStrFromDateStr(s?.endDateTime || "-") },
                    { title: "Varighed", value: getDurationFromMinutes(s?.duration) },
                ]},
            ];

        if (s.location) {
            eventdata.push({
                title: "Sted",
                byDefaultOpen: true,
                dropdownValues: [
                    { title: "Sted", value: s.location || "" }
                ]

            })
        }
        if (isMemberSignedIn && s.materials && s.materials.length > 0 && signedUpSessionIds.includes(s.sessionId!)) {
            eventdata.push(
                {
                    title: "Kursusmateriale",
                    byDefaultOpen: true,
                    dropdownValues: [<Material hasSignedUp={(currentEvent.signedUp||false) && signedUpSessionIds.includes(s.sessionId!)} data={JSON.stringify(elementData)} eventMaterials={s?.materials || []} />]


                });
        }
        return eventdata;
    }, [isMemberSignedIn, currentEvent, signedUpSessionIds, elementData]);
    return (
        <div className={style.sessioncontainer}>
            <div className={style.sessiontitle}>Sessioner</div>

            {currentEvent.sessions?.map((s) =>
                <Fragment key={`${s.sessionId}`}>
                    <div className={style.divtitle}>{s.name!}</div>
                    <CustomAccordion
                        data={createAccordionData(s)}
                    />
                    {currentEvent.customSessionRegistration && currentEvent.signedUp && !hasSessionEnded(s.endDateTime) &&
                        <div className={style.registerbutton}>
                            {signedUpSessionIds.includes(s.sessionId!) ? <div className={style.sessionstatus}>
                                <div className={style.infomessagecontainer}>
                                    <div className={style.infomessage}>{elementData.alreadySignedupSessionText}</div>
                                </div>
                                {!hasSessionEnded(s.endDateTime) && <CTAButton
                                    onClick={() => props.openSessionModal(s, true)}>{props.elementData.cancelSessionButtonLabel || "Afmeld session"}
                                </CTAButton>}</div> :
                                <div className={style.signupButton}>
                                    {currentEvent.signedUp&&<CTAButton disabled={hasSessionEnded(s.endDateTime) || eventEnded}
                                        onClick={() => props.openSessionModal(s)}>
                                        {elementData.signupSessionButtonLabel || "Tilmeld session"}</CTAButton>}
                                </div>}
                        </div>}
                        <Refusion memberid={props.memberid} currentItem={s} 
                        umbracoSetting={{title:elementData.refusionTitle||"",helpText:elementData.refusionHelpText,buttonText:elementData.refusionButtonLabel||"Søg refusion",id:"",contentTypeAlias:""}} />
                </Fragment>

            )}
        </div>
    )
}
export default Sessions;
